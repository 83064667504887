










import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "YouTubeURL",
  props: {
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    function update(): void {
      if (_value.value.includes("youtu.be")) {
        _value.value = `https://www.youtube.com/embed/${_value.value.split("/")[3]}`;
      }
      else if (_value.value.includes("/watch?v=")) {
        _value.value = `https://www.youtube.com/embed/${_value.value.split("=")[1]}`;
      }
      emit("change");
    }

    return {
      update,
      _value,
    };
  },
});
