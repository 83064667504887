import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vm.loading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{staticClass:"ma-8",attrs:{"color":"primary","indeterminate":""}})],1):_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.vm.type === 'day' ? 'Dag' : _vm.vm.type === 'week' ? 'Uke' : 'Måned'))]),_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.vm.type = 'day'}}},[_c(VListItemTitle,[_vm._v("Dag")])],1),_c(VListItem,{on:{"click":function($event){_vm.vm.type = 'week'}}},[_c(VListItemTitle,[_vm._v("Uke")])],1),_c(VListItem,{on:{"click":function($event){_vm.vm.type = 'month'}}},[_c(VListItemTitle,[_vm._v("Måned")])],1)],1)],1)],1)],1),_c(VCard,[_c(VCalendar,{ref:"calendar",staticStyle:{"height":"80vh"},attrs:{"events":_vm.events,"type":_vm.vm.type,"weekdays":_vm.vm.weekdays,"event-overlap-mode":"column","first-time":"9","interval-count":"13","category-show-all":"","show-month-on-first":"","show-week":""},on:{"click:date":_vm.viewDay,"click:event":_vm.showTeamCard,"click:more":_vm.viewDay},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var start = ref.start;
var event = ref.event;
return [_c(VAppBar,{attrs:{"flat":"","dense":"","height":"25px","color":event.color}},[(event.pros)?_c('b',[_vm._v(_vm._s(event.pros))]):_vm._e(),(event.pros)?_c(VSpacer):_vm._e(),_c('b',[_vm._v(_vm._s(event.name))]),_c(VSpacer),_c('b',[_vm._v(" "+_vm._s(_vm.getClockTimeFromUnix(event.start / 1000))+" - "+_vm._s(_vm.getClockTimeFromUnix(event.end / 1000)))])],1),_c(VAppBar,{attrs:{"flat":"","dense":"","height":"25px","color":event.color}},[_vm._v(" "+_vm._s(event.parents)+" foreldre "),_c(VSpacer),_vm._v(" "+_vm._s(event.students)+" deltagere ")],1),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(event.birthYears.join(', '))+" "),_c('br'),_vm._v(" "+_vm._s(event.room)+" ")])]}}]),model:{value:(_vm.vm.focus),callback:function ($$v) {_vm.$set(_vm.vm, "focus", $$v)},expression:"vm.focus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }