


















import VideoListEditor from "@/components/courses/VideoListEditor.vue";
import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import { updateCourseSession } from "@/api/course-session-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "EditTheory",
  components: { VideoListEditor },
  props: {
    courseSession: {
      type: Object as PropType<Learnlink.CourseSession.default>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      theoryDescription: "",
      theoryVideoURLs: [] as string[],
    });

    const fetchCourseSessions = inject<() => void>("fetchCourseSessions");

    onMounted(() => {
      vm.theoryDescription = props.courseSession.theoryDescription;
      vm.theoryVideoURLs = props.courseSession.theoryVideoURLs;
    });

    async function update() {
      try {
        await updateCourseSession({
          ID: props.courseSession.ID,
          theoryDescription: vm.theoryDescription,
          theoryVideoURLs: vm.theoryVideoURLs,
        });
        store.dispatch("displayAlert", {
          message: "Oppdatert!",
          color: "success",
        });
        fetchCourseSessions && fetchCourseSessions();
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }
    }

    return {
      update,
      vm,
    };
  },
});
