import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/courseSessions";

export async function createCourseSession(session: Learnlink.CourseSession.Create): Promise<Learnlink.CourseSession.default> {
  const courseSessionRes = await axios.post<Learnlink.CourseSession.default>(
    `${ENDPOINT}/`,
    session
  );
  return courseSessionRes.data;
}

export async function getCourseSessions(courseID: string): Promise<Learnlink.CourseSession.default[]> {
  const courseSessionRes = await axios.get<Learnlink.CourseSession.default[]>(
    `${ENDPOINT}/${courseID}`
  );

  return courseSessionRes.data;
}

export async function updateCourseSession(session: Learnlink.CourseSession.Update): Promise<Learnlink.CourseSession.default> {
  const courseSessionRes = await axios.put<Learnlink.CourseSession.default>(
    `${ENDPOINT}/${session.ID}`,
    session
  );
  return courseSessionRes.data;
}

export async function deleteCourseSession(ID: string): Promise<Learnlink.CourseSession.default> {
  const courseSessionRes = await axios.delete<Learnlink.CourseSession.default>(
    `${ENDPOINT}/${ID}`
  );
  return courseSessionRes.data;
}
