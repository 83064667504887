














































































































import { defineComponent, inject, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import * as TeamService from "@/api/team-service";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "CreateTeamDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const mountTeams = inject("mountTeams") as () => void;

    const vm = reactive({
      courses: [] as Learnlink.Course.default[],
      loading: false,
      team: {
        birthYears: [],
        courseID: "",
        internalNote: "",
        salary: 7000,
        sellerNote: "",
        size: 7,
        title: "",
      } as Learnlink.Team.Create,
    });

    const courses = store.state.courses.map((course) => {
      return {
        readableTitle: `${course.title} (${course.weeks} ukers kurs)`,
        ...course,
      };
    });

    function close() {
      _value.value = false;
    }

    async function createTeam() {
      vm.loading = true;

      await TeamService.createTeam({
        birthYears: vm.team.birthYears,
        courseID: vm.team.courseID,
        internalNote: vm.team.internalNote,
        salary: vm.team.salary * 100,
        size: vm.team.size,
        title: vm.team.title,
      });

      await store.dispatch("displayAlert", {
        color: "success",
        message: "Team opprettet! 🥳",
      });
      if (mountTeams) mountTeams();
      close();

      vm.loading = false;
    }

    return {
      _value,
      close,
      courses,
      createTeam,
      vm,
    };
  },
});
