




































































































































































































































































import { addPlatformLocation, migrateLocations, removePlatformlocation } from "@/api/platform-settings-service";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { PropType, Ref, computed, defineComponent, inject, ref } from "@vue/composition-api";
import TeamDialoge from "../teams/TeamDialog.vue";

export default defineComponent({
  name: "EditPlatformLocations",
  components: { TeamDialoge },
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.View.Admin.Thumbnail[]>,
      required: true,
    },
  },
  setup(props) {
    const makePlatformLocation = ref(false);
    const makeLocationTitle = ref("");
    const loading = ref(false);
    const deleteLoading = ref(false);
    const confirmDeleteLocationPrompt: Ref<Learnlink.PlatformSettings.Location | null> = ref(null);
    const migrateLocationLoading = ref(false);
    const migratingLocation: Ref<string | null> = ref(null);
    const choosenLocation: Ref<string | null> = ref(null);
    const editTeam: Ref<Learnlink.Team.View.Admin.Thumbnail | null> = ref(null);
    const refreshTeams = inject<() => Promise<void>>("mountTeams");

    async function makeLocation() {
      if (loading.value) return;
      if (!makeLocationTitle.value.length) {
        return store.dispatch("displayAlert", {
          color: "error",
          message: "Titelen må ha minst et bokstav."
        });
      }
      loading.value = true;
      try {
        const payload = {
          address: "",
          ID: makeLocationTitle.value.trim().replace(" ", "-"),
          mapURL: "",
          title: makeLocationTitle.value.trim()
        };
        await addPlatformLocation(payload);
        makeLocationTitle.value = "";
        makePlatformLocation.value = false;

        store.state.platformSettings.teams.locations.push(payload);
        store.commit("mountTeamSettings", store.state.platformSettings.teams);
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt, sjekk nettleser consolen.",
        });
      }

      loading.value = false;
    }

    async function deleteLocation(id: string) {
      if (deleteLoading.value) return;
      deleteLoading.value = true;

      try {
        await removePlatformlocation(id);
        makeLocationTitle.value = "";
        makePlatformLocation.value = false;
        store.state.platformSettings.teams.locations = store.state.platformSettings.teams.locations.filter((value) => value.ID !== id);
        store.commit("mountTeamSettings", store.state.platformSettings.teams);
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt, sjekk nettleser consolen.",
        });
      }

      confirmDeleteLocationPrompt.value = null;
      deleteLoading.value = false;
    }

    async function migrateLocation() {
      if (migrateLocationLoading.value) return;
      if (!choosenLocation.value) return;
      if (!migratingLocation.value) return;

      migrateLocationLoading.value = true;

      try {
        await migrateLocations({
          old: migratingLocation.value,
          new: choosenLocation.value,
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt, sjekk nettleser consolen.",
        });
      }

      if (refreshTeams) refreshTeams();
      migratingLocation.value = null;
      choosenLocation.value = null;
      migrateLocationLoading.value = false;
    }

    const collectionUnknownLocations = computed(() => {
      const result: Array<{location: string, teams: Array<Learnlink.Team.View.Admin.Thumbnail>}> = [];
      for (const team of props.teams) {
        if (team.course.location !== "physical") continue;
        const location = team.location || "ingen lokasjon";

        if (store.state.platformSettings.teams.locations.find((value) => value.title === location)) continue;
        const existingTeam = result.find((value) => value.location === location);

        if (existingTeam) {
          existingTeam.teams.push(team);
        }
        else {
          result.push({
            location: location,
            teams: [team],
          });
        }
      }
      return result;
    });

    return {
      makePlatformLocation,
      makeLocationTitle,
      loading,
      makeLocation,
      deleteLoading,
      deleteLocation,
      migratingLocation,
      migrateLocation,
      choosenLocation,
      collectionUnknownLocations,
      migrateLocationLoading,
      editTeam,
      confirmDeleteLocationPrompt,
      locations: computed(() => store.state.platformSettings.teams.locations),
      migratingLocationComputed: computed(() => collectionUnknownLocations.value.find((value) => migratingLocation.value === value.location)),
    };
  },
});
