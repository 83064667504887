import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VAppBar,{attrs:{"flat":""}},[_c('h2',[_vm._v("Kurs")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","x-small":""},on:{"click":function($event){return _vm.openCreateCourseDialog()}}},[_vm._v(" Nytt kurs ")])],1),_c(VContainer,[_c(VExpansionPanels,{attrs:{"flat":""}},_vm._l((_vm.courseMap),function(value,name,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,[(+name === 0)?_c('h4',[_vm._v(" Basics ")]):_c('h4',[_vm._v(" "+_vm._s(name)+" ukers kurs ")])]),_c(VExpansionPanelContent,_vm._l((value),function(course){return _c('div',{key:course.ID,staticClass:"pa-2"},[_c('p',{staticClass:"courseName",staticStyle:{"font-size":"12px"},on:{"click":function($event){return _vm.openEditCourseDialog(course)}}},[_vm._v(" "+_vm._s(course.title)+" "),_c('span',{staticClass:"grey--text ml-4"},[_vm._v(_vm._s(course.location))])]),_c(VDivider,{staticClass:"mt-2"})],1)}),0)],1)}),1)],1),(_vm.vm.createCourseDialogVisible)?_c('create-course-dialog',{model:{value:(_vm.vm.createCourseDialogVisible),callback:function ($$v) {_vm.$set(_vm.vm, "createCourseDialogVisible", $$v)},expression:"vm.createCourseDialogVisible"}}):_vm._e(),(_vm.vm.editCourseDialogVisible)?_c('edit-course-dialog',{attrs:{"course":_vm.vm.course},model:{value:(_vm.vm.editCourseDialogVisible),callback:function ($$v) {_vm.$set(_vm.vm, "editCourseDialogVisible", $$v)},expression:"vm.editCourseDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }