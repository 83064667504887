




















import { updateCourseSession } from "@/api/course-session-service";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "EditCodeSnippet",
  props: {
    courseSession: {
      type: Object as PropType<Learnlink.CourseSession.default>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      codeSnippet: "",
    });
    const fetchCourseSessions = inject("fetchCourseSessions") as () => void;

    onMounted(() => {
      vm.codeSnippet = props.courseSession.codeSnippet;
    });

    async function updateSnippet() {
      try {
        await updateCourseSession({
          ID: props.courseSession.ID,
          codeSnippet: vm.codeSnippet,
        });
        store.dispatch("displayAlert", {
          message: "Oppdatert!",
          color: "success",
        });
        fetchCourseSessions && fetchCourseSessions();
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }
    }

    return {
      updateSnippet,
      vm,
    };
  },
});
