



































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import CreateCourseDialog from "./CreateCourseDialog.vue";
import EditCourseDialog from "./EditCourseDialog.vue";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "ListAndCreateCourses",
  components: {
    CreateCourseDialog,
    EditCourseDialog,
  },
  setup() {
    const vm = reactive({
      createCourseDialogVisible: false,
      course: {} as Learnlink.Course.default,
      editCourseDialogVisible: false,
    });

    const courseMap = computed(() => {
      const _courseMap: Record<string, Learnlink.Course.default[]> = {};

      store.state.courses.forEach((course) => {
        if (_courseMap[course.weeks]) {
          _courseMap[course.weeks].push(course);
        }
        else {
          _courseMap[course.weeks] = [course];
        }
      });
      return _courseMap;
    });

    function openCreateCourseDialog(): void {
      vm.createCourseDialogVisible = true;
    }

    function openEditCourseDialog(course: Learnlink.Course.default): void {
      vm.course = course;
      vm.editCourseDialogVisible = true;
    }

    return {
      courseMap,
      courses: computed(() => store.state.courses),
      openCreateCourseDialog,
      openEditCourseDialog,
      vm,
    };
  },
});
