





























import { createCourse } from "@/api/course-service";
import EditCourse from "@/components/courses/EditCourse.vue";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import handleError from "@/helpers/errors";
import store from "@/store/store";

export default defineComponent({
  name: "CreateCourseDialog",
  components: {
    EditCourse,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      course: {
        content: "",
        instructions: "",
        sessions: 0,
        stripePriceID: "",
        title: "",
        weeks: 0,
      } as Learnlink.Course.Create,
      loading: false,
    });

    async function submit(): Promise<void> {
      vm.loading = true;

      try {
        await createCourse(vm.course);
        store.dispatch("displayAlert", {
          message: "Opprettet!",
          color: "success",
        });
        await store.dispatch("mountCourses");
        _value.value = false;
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }

      vm.loading = false;
    }

    return {
      _value,
      submit,
      vm,
    };
  },
});
