



























































































































import EditTheory from "@/components/courses/EditTheory.vue";
import { defineComponent, inject, onMounted, PropType, reactive, ref, watch } from "@vue/composition-api";
import { deleteCourseSession, updateCourseSession } from "@/api/course-session-service";
import EditChallenge from "@/components/courses/EditChallenge.vue";
import EditCodeSnippet from "@/components/courses/EditCodeSnippet.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "EditCourseSession",
  components: {
    EditCodeSnippet,
    EditChallenge,
    EditTheory,
  },
  props: {
    courseSession: {
      type: Object as PropType<Learnlink.CourseSession.default>,
      required: true,
    },
    course: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      title: "",
    });
    const modal = ref(false);
    const fetchCourseSessions = inject<() => void>("fetchCourseSessions");
    const date = ref(props.courseSession.open?.date ?? undefined);
    const time = ref(props.courseSession.open?.time ?? undefined);
    const computedDateTime = ref("Avaliable anytime");

    date.value = props.courseSession.open?.date ?? undefined;
    time.value = props.courseSession.open?.time ?? undefined;

    const reconcile = () => {
      if (date.value) {
        computedDateTime.value = `${date.value ?? "Any date"} ${time.value ?? "00:00"}`;
        props.courseSession.open = {
          date: date.value ?? "2023-01-01",
          time: time.value ?? "00:00",
        };
      }
      else {
        computedDateTime.value = "Avaliable anytime";
        props.courseSession.open = undefined;
      }
    };

    watch(date, reconcile);
    watch(time, reconcile);
    reconcile();

    onMounted(() => {
      vm.title = props.courseSession.title;
    });

    async function deleteSession() {
      await deleteCourseSession(props.courseSession.ID);

      store.dispatch("displayAlert", {
        message: "Slettet!",
        color: "success",
      });
      fetchCourseSessions && fetchCourseSessions();
    }

    async function remove() {
      modal.value = false;
      date.value = undefined;
      time.value = undefined;

      await updateCourseSession({
        ID: props.courseSession.ID,
        open: {} as never,
      });
    }

    async function save() {
      modal.value = false;

      await updateCourseSession({
        ID: props.courseSession.ID,
        open: props.courseSession.open,
      });
    }

    async function updateTitle() {
      try {
        await updateCourseSession({
          ID: props.courseSession.ID,
          title: vm.title,
        });
        store.dispatch("displayAlert", {
          message: "Oppdatert!",
          color: "success",
        });
        fetchCourseSessions && fetchCourseSessions();
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }
    }

    return {
      deleteSession,
      updateTitle,
      vm,
      modal,
      save,
      remove,
      date,
      time,
      computedDateTime,
    };
  },
});
