



















































import { defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import YouTubeURL from "./YouTubeURL.vue";

export default defineComponent({
  name: "VideoListEditor",
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  components: {
    YouTubeURL,
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      newVideoURL: "",
    });

    async function addURL() {
      _value.value.push(vm.newVideoURL);
      vm.newVideoURL = "";
      emit("update");
    }

    async function removeURL(url: string) {
      const indexOfURL = _value.value.indexOf(url);
      indexOfURL !== undefined && _value.value.splice(indexOfURL, 1);
      emit("update");
    }

    return {
      _value,
      addURL,
      emit,
      removeURL,
      vm,
    };
  },
});
