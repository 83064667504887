import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mb-4",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"9"}},[_c(VTextField,{attrs:{"label":"Tittel","outlined":"","hide-details":""},on:{"change":function($event){return _vm.updateTitle()}},model:{value:(_vm.vm.title),callback:function ($$v) {_vm.$set(_vm.vm, "title", $$v)},expression:"vm.title"}})],1),_c(VCol,[_c(VBtn,{attrs:{"color":"error","x-small":""},on:{"click":function($event){return _vm.deleteSession()}}},[_vm._v(" Slett session ")])],1)],1),(_vm.course.location === 'calendar')?_c(VCol,[_c(VDialog,{ref:"dialog",attrs:{"persistent":"","width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Når åpner denne kalenderen?","prepend-icon":"mdi-calendar","readonly":"","onclick":"vm.coureSession.open = {time: '00-00', date: '2023-01-01'}"},model:{value:(_vm.computedDateTime),callback:function ($$v) {_vm.computedDateTime=$$v},expression:"computedDateTime"}},'v-text-field',attrs,false),on))]}}],null,false,748771395),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c(VTimePicker,{attrs:{"format":"ampm"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c(VDatePicker,{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer),_c(VBtn,{attrs:{"variant":"text","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"variant":"text","color":"primary"},on:{"click":function($event){return _vm.remove()}}},[_vm._v(" Fjern dato ")]),_c(VBtn,{attrs:{"variant":"text","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" OK ")])],1)],1)])],1):_vm._e(),_c(VDivider,{staticClass:"my-6"}),_c(VExpansionPanels,{attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" Teori ")]),_c(VExpansionPanelContent,[_c('edit-theory',{attrs:{"course-session":_vm.courseSession}})],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" Challenges ")]),_c(VExpansionPanelContent,[_c('edit-challenge',{attrs:{"course-session":_vm.courseSession}})],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" Code snippets ")]),_c(VExpansionPanelContent,[_c('edit-code-snippet',{attrs:{"course-session":_vm.courseSession}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }