


























































































































































































































































import { defineComponent, PropType, reactive, ref, set, watch, watchEffect } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "EditCourse",
  props: {
    value: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = reactive({
      ...props.value,
    });

    watchEffect(() => {
      emit("input", _value);
    });

    function openURL(url: string): void {
      const win = window.open(url, "_blank");
      win?.focus();
    }

    const genericInfoRef = ref(!!props.value.genericInformation);
    watch(genericInfoRef, (a) => {
      const defaultGenericInformation: Learnlink.Course.default["genericInformation"] = {
        author: "",
        message: "",
        profilePicture: "https://app.learnlink.no/img/nophoto.jpeg",
      };
      set(_value, "genericInformation", a ? {
        ...defaultGenericInformation,
      } : undefined);
    });

    return {
      openURL,
      _value,
      genericInfoRef,
    };
  },
});
