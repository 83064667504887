






































import { computed, defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import TeamThumbnail from "@/components/teams/TeamThumbnail.vue";

export default defineComponent({
  name: "TeamList",
  components: { TeamThumbnail },
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.View.FullMetadata[]>,
      required: true,
    },
  },
  setup(props) {
    const teamProMap = computed(() => {
      const map = {} as Record<string, Array<Learnlink.Team.Team & {
        verboseSellers: Array<{
          uid: string,
          firstName: string,
          lastName: string
        }>
      }>>;
      props.teams.forEach((team) => {
        const uid = team.sellers[0] || "Mangler pro";
        map[uid] ? map[uid].push(team as never) : map[uid] = [team as never];
      });
      return map;
    });

    return {
      teamProMap,
    };
  },
});
