












































































































































import { updateCourse } from "@/api/course-service";
import { createCourseSession, getCourseSessions } from "@/api/course-session-service";
import Moment from "@/services/moment";
import { defineComponent, onMounted, PropType, provide, reactive, ref, watchEffect } from "@vue/composition-api";
import EditCourse from "./EditCourse.vue";
import EditCourseSession from "./EditCourseSession.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "EditCourseDialog",
  components: { EditCourse, EditCourseSession },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    course: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      course: { ...props.course },
      courseSessions: [] as Learnlink.CourseSession.default[],
      loading: false,
    });

    onMounted(async () => {
      await fetchCourseSessions();
    });

    async function archive() {
      vm.course.archived = Moment().unix();
      await update();
    }

    async function createSession() {
      try {
        await createCourseSession({ courseID: props.course.ID });
        await fetchCourseSessions();
        store.dispatch("displayAlert", {
          message: "Opprettet!",
          color: "success",
        });
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }
    }

    async function fetchCourseSessions(): Promise<void> {
      vm.courseSessions = await getCourseSessions(props.course.ID);
    }

    async function update() {
      vm.loading = true;
      try {
        await updateCourse(vm.course);
        store.dispatch("displayAlert", {
          message: "Oppdatert!",
          color: "success",
        });
        await store.dispatch("mountCourses");
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          message: "Oops! Noe gikk galt!",
          color: "error",
        });
      }
      vm.loading = false;
    }

    provide("fetchCourseSessions", fetchCourseSessions);

    return {
      _value,
      archive,
      createSession,
      update,
      vm,
    };
  },
});
