









































































































































import { CalendarEvent } from "vuetify";
import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { getClockTimeFromUnix } from "@/utils/timeUtils";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamCalendar",
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.View.Admin.Thumbnail[]>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      currentTeam: {} as Learnlink.Team.View.Admin.Thumbnail,
      focus: "",
      loading: false,
      sessions: [] as Learnlink.TeamSession.default[],
      type: "month",
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    });

    const openTeamDialog = inject<(teamID: string) => void>("openTeamDialog");

    const events = computed(() => {
      vm.sessions = props.teams.map(team => team.teamSessions).flat();
      const teamMap: Record<string, Learnlink.Team.View.Admin.Thumbnail> = {};

      props.teams.forEach(team => {
        teamMap[team.ID] = team;
      });

      return vm.sessions.map((date) => {
        return {
          ID: date.ID,
          birthYears: teamMap[date.teamID].birthYears,
          color: !teamMap[date.teamID].sellers.length ? "red"
            : !teamMap[date.teamID].overrideRoomURL || !teamMap[date.teamID].overrideRoomURL.includes("zoom.us/") ? "orange"
              : teamMap[date.teamID].overrideRoomURL.includes("zoom.us/") ? "green"
                : "red",
          end: date.endTime * 1000,
          name: teamMap[date.teamID].title,
          parents: teamMap[date.teamID].parents.length,
          pros: teamMap[date.teamID].verboseSellers?.map(seller => `${seller.firstName} ${seller.lastName}`).join(", "),
          room: teamMap[date.teamID].roomUsername,
          start: date.startTime * 1000,
          students: teamMap[date.teamID].students.length,
          timed: true,
        } as CalendarEvent;
      });
    });

    function viewDay({ date }: Record<"date", string>) {
      vm.focus = date;
      vm.type = "day";
    }

    function showTeamCard(event: CalendarEvent) {
      const currentSession = vm.sessions.filter(session => session.ID === event.event.ID)[0];
      vm.currentTeam = props.teams.filter(team => team.ID === currentSession.teamID)[0];
      if (openTeamDialog) openTeamDialog(vm.currentTeam.ID);
    }

    return {
      events,
      getClockTimeFromUnix,
      showTeamCard,
      viewDay,
      vm,
    };
  },
});
